import Layout from "@components/Layout";
import Navbar from "@components/Navbar";
import styles from "@styles/404.module.css";
import { Social, Tab, useWindowSize } from "@lib/utils";
import { type WindowSize } from "@lib/utils";
import Head from "next/head";
import { fetchData } from "@lib/strapi";
import Footer from "@components/Footer";

interface PropsStruct {
  title: string;
  socials: Social[];
  tabs: Tab[];
}

export async function getStaticProps() {
  const data = await fetchData("/global", {
    populate: {
      title: "*",
    },
  });

  const data3 = await fetchData("/socials", {
    populate: {
      link: "*",
      image: "*",
      name: "*",
    },
  });

  const data4 = await fetchData("/navigations", {
    populate: {
      text: "*",
      link: "*",
      outsideLink: "*",
    },
  });

  data4.data.sort((a: Tab, b: Tab) => a.id - b.id);

  return {
    props: {
      title: data.data.attributes.title,
      socials: data3.data,
      tabs: data4.data,
    },
  };
}

export default function Custom404({ title, socials, tabs }: PropsStruct) {
  const size: WindowSize = useWindowSize();

  return (
    <>
      <Head>
        <title>{title + " - 404"}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <>
        <Layout className={styles.main}>
          <Navbar header={null} size={size} socials={socials} tabs={tabs} />
          <div className={styles.container}>
            <h1 className={styles.errorCode}>404</h1>
            <div className={styles.divider}></div>
            <h2 className={styles.errorText}>Denna sida kunde inte hittas</h2>
          </div>
        </Layout>
        <Footer socials={socials} tabs={tabs} />
      </>
    </>
  );
}
